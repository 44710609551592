<template>
  <article v-if="ready" class="animate-fade-in">
    <div class="text-center block relative">
      <figure class="relative group cursor-pointer" @click.prevent.stop="playVideo">
        <transition name="fade-fast">
          <SharedButtonVideo v-if="!videoPlaying" title="Play" class="absolute top-1/2 left-1/2 z-10 mt-20 -ml-6 group-hover:bg-blue-500 group-hover:scale-105 animate-slide-down-fade-in01s"/>
        </transition>

        <div class="rounded-lg -mt-8 md:-mt-5 animate-fade-in transition-all scale-105 md:scale-110 bg-black relative">
          <transition name="fade">
            <img
              v-if="!videoPlaying && !videoPlayed"
              class="w-full h-full absolute z-10"
              src="https://content.prod.platform.metawin.com/promo/Aluna-D41DDC5F-167D-49E7-A653-9FFFFD80DB04.png"
            >
          </transition>

          <video
            ref="VideoPlayer"
            playsinline
            disableremoteplayback
            width="480"
            height="480"
            preload="none"
            class="w-full max-w-none relative z-0"
            poster="https://content.prod.platform.metawin.com/promo/Aluna-D41DDC5F-167D-49E7-A653-9FFFFD80DB04.png"
            @ended="videoEnded()"
          >
            <source src="https://content.prod.platform.metawin.com/promo/Aluna-D41DDC5F-167D-49E7-A653-9FFFFD80DB04.mp4" type="video/mp4">
          </video>
        </div>
      </figure>

      <div class="inline-block mt-5 w-full ">
        <img
          src="/img/promo/150eth-heading-horizontal.png"
          width="500"
          height="128"
          class="inline-block w-full max-w-[380px] relative animate-scale-up-once z-10"
        >
      </div>

      <h2 class="mt-0 mb-2 text-xl">({{ valUsdDisplay }})</h2>

      <ButtonButton
        type="button"
        class="block w-full mt-3"
        theme="gold"
        @click.prevent.stop="action()"
      >
        {{ buttonText }}
      </ButtonButton>

      <p class="text-slate-100 text-sm mt-4">
        <strong>{{ $truncateNumber(stats.sweepstakesCompleted, 0, true) }}+</strong> competitions completed to date
      </p>

      <CheckboxConfirm
        class="mt-4 text-slate-100"
        label="Don't show again"
        name="Confirm"
        size="sm"
        :value="welcomePromoModalHide"
        @changed="confirm"
      />
    </div>
  </article>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';

export default defineComponent({
  name: 'ModalInfoContentWelcomePromo150eth',
  emits: [
    'close',
  ],
  data() {
    return {
      isLoadingData: true,
      stats: {
        sweepstakesCompleted: 0,
        sweepstakesNFTPrizeValue: 0,
        totalSignUps: 0,
        sweepstakesEntries: 0,
      },
      statsDecimals: {
        sweepstakesNFTPrizeValue: 2,
      },
      newStats: null,
      ready: false,
      ethValue: 150,
      cmsData: null,
      videoPlaying: false,
      videoPlayed: false,
    };
  },
  computed: {
    ...mapState(useUiStore, ['infoModalStats',]),
    ...mapWritableState(useUiStore, ['welcomePromoModalHide',]),
    ...mapState(useCryptoStore, ['exchangeRateEthUsd',]),
    ...mapState(useAuthStore, ['isUserLogged',]),

    valUsdDisplay() {
      const priceUsd = (this.ethValue * this.exchangeRateEthUsd).toFixed();
      if (priceUsd < 1000) {
        return this.ethValue !== null ? this.$truncateNumber(priceUsd, 2) : '';
      } else {
        return this.ethValue !== null ? this.$formatMoney(priceUsd, 'USD', false) : '';
      }
    },

    buttonText() {
      if (this.isUserLogged) {
        return 'Enter now';
      } else {
        return 'Connect to enter';
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(useUiStore, ['openConnectModal',]),
    close() {
      this.$emit('close');
    },
    init() {
      this.newStats = this.infoModalStats;
      this.cmsData = this.infoModalStats?.cmsData;
      this.newStats.sweepstakesNFTPrizeValue = this.$round(this.newStats.sweepstakesNFTPrizeValue, 4);
      this.stats = this.newStats;
      this.ready = true;
    },
    confirm() {
      this.welcomePromoModalHide = !this.welcomePromoModalHide;
    },
    action() {
      this.$emit('close');
      if (this.isUserLogged) {
        this.$emit('close');
        return navigateTo(this.cmsData?.heading);
      } else {
        this.openConnectModal();
      }
    },
    playVideo() {
      this.videoPlaying = !this.videoPlaying;
      this.videoPlayed = true;
      if (this.videoPlaying) {
        this.$refs.VideoPlayer?.play();
      } else {
        this.$refs.VideoPlayer?.pause();
      }
    },
    videoEnded() {
      this.videoPlaying = false;
      this.videoPlayed = false;
      this.$refs.VideoPlayer.currentTime = 0;
    },
  },
});
</script>
