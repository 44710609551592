<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <img
        src="~/assets/img/logos/MetaWin-logo-white.svg"
        alt="MetaWin logo"
        width="165"
        height="24"
        class="inline-block w-full max-w-[220px] animate-slide-down-fade-in06s"
      >
      <div class="text-slate-100 mt-4 animate-slide-down-fade-in04s text-left prose prose-invert mb-5">
        <p class="text-gold text-center font-bold">Welcome to Winning in Web3!</p>
          <CmsContentStandard :query="query" class="text-slate-100"/>
        <p class="text-green font-bold text-center">Welcome to the Future of Winning.</p>
      </div>
      <ButtonConnect
        class="w-full"
        @clicked="close"
      />
    </div>
  </article>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';

export default defineComponent({
  name: 'ModalInfoContentWelcome',
  emits: [
    'close',
  ],
  data() {
    return {
      isLoadingData: true,
      stats: {
        sweepstakesCompleted: 0,
        sweepstakesNFTPrizeValue: 0,
        totalSignUps: 0,
        sweepstakesEntries: 0,
      },
      statsDecimals: {
        sweepstakesNFTPrizeValue: 2,
      },
      newStats: null,
      ready: false,
      query: `*[_type == "info-modals" && modalId == 'welcomeModal'][0]{
              content
            }`,
    };
  },
  computed: {
    ...mapState(useUiStore, ['infoModalStats',]),
    ...mapWritableState(useUiStore, ['welcomeModalHide',]),
  },
  mounted() {
    this.init();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    init() {
      this.newStats = this.infoModalStats;
      this.newStats.sweepstakesNFTPrizeValue = this.$round(this.newStats.sweepstakesNFTPrizeValue, 4);
      this.stats = this.newStats;
      this.ready = true;
    },
  },
});
</script>
