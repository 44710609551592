<template>
  <ModalMain
    :modal-width-mode="infoModalWidthMode"
    :info-modal-type="infoModalType"
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <ModalInfoContentWelcome
        v-if="infoModalType === 'Welcome'"
        @close="close"
      />

      <ModalInfoContentUnclaimedItem
        v-if="infoModalType === 'NewItem'"
        :info-modal-content="infoModalContent"
        @close="close"
      />

      <ModalInfoContentWelcomePromo150eth
        v-if="infoModalType === 'WelcomePromo150eth'"
        @close="close"
      />

      <ModalInfoContentWithdrawalConfirmation
        v-if="infoModalType === 'WithdrawalConfirmation'"
        :info-modal-content="infoModalContent"
        @close="close"
      />

      <ModalInfoContentCompetitionReminder
        v-if="infoModalType === 'CompetitionReminder'"
        @close="close"
      />

      <ModalInfoContentCms
        v-if="infoModalType === 'Cms'"
        :info-modal-content="infoModalContent"
        @close="close"
      />

      <ModalAccountClaimItemConfirmation
        v-if="infoModalType === 'claimItemConfirm'"
        @close="close"
      />
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState } from 'pinia';

export default defineComponent({
  name: 'ModalInfoMain',
  computed: {
    ...mapWritableState(useUiStore, ['showInfoModal', 'infoModalType', 'infoModalContent', 'infoModalWidthMode', 'showAnnouncementModal',]),
  },
  watch: {
    errorModalType(val) {
      this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'Info', value: val, });
      if (val === 'closeModal') { this.close(); }
    },
  },
  methods: {
    close() {
      this.showInfoModal = false;
      this.infoModalType = null;
      this.infoModalContent = null;
      this.infoModalWidthMode = null;
    },
  },
});
</script>
