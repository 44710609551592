<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <div class="relative text-[42px] mx-auto inline-block text-blue-500 animate-glow-slow-blue">
        <span class="icon-ico-ringing absolute -left-[1px] animate-scale-up-once"/>
        <span class="relative block icon-ico-bell -left-[1px] transition-colors animate-bell"/>
      </div>

      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">Competition reminder</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>We'll send you a reminder email 1 hour before this competition ends</p>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-12"
        @click.prevent="close()"
      >
        Close
      </ButtonButton>

      <CheckboxConfirm
        class="mt-4"
        label="Don't show again"
        name="Confirm"
        :value="reminderModalHide"
        @changed="confirm"
      />
    </div>
  </article>
</template>

<script>
import { mapWritableState } from 'pinia';

export default defineComponent({
  name: 'ModalInfoContentCompetitionReminder',
  emits: [
    'close',
  ],
  computed: {
    ...mapWritableState(useUiStore, ['reminderModalHide',]),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.reminderModalHide = !this.reminderModalHide;
    },
  },
});
</script>
