<template>
  <div class="px-4 md:px-7 pt-2 w-full text-center">
    <h1 class="text-2xl tracking-wide font-semibold mb-2 text-center">{{ data?.heading }}</h1>
    <div class="flex justify-center">
      <img
        v-if="claimItem?.type === 'Hamburger'"
        :src="handlePresentNFTIcon(claimItem?.tokenId, claimItem?.network)"
        width="200"
        height="200"
        class="rounded-md"
      >
      <img
        v-else
        src="~/assets/img/misc/gift-icon.png"
        class="rounded-lg"
        height="200"
        width="200"
      >
    </div>

    <p class="text-slate-200 mt-4 leading-tight">{{ data?.message }}</p>

    <div class="max-w-[370px] w-full mx-auto mt-3 inline-block relative">
      <input
        v-model="walletAddress"
        class="block relative w-full bg-black/50 border-slate-100/20 rounded-lg py-3 px-4 border-2 outline-none focus:border-white/40 focus:ring-0 autofill:bg-slate-900 transition-all disabled:text-slate-100 mt-2"
        :class="[
          !isValid ? 'px-4' : 'pr-10'
        ]"
        name="address"
        type="text"
        placeholder="Enter wallet address"
        @input="validateAddress()"
      >
      <span
        v-if="isValid"
        class="icon-ico-tick text-green text-2xl top-[45%] right-2 absolute z-10 transition-all pointer-events-none duration-300 -translate-y-2 input-success:opacity-100 input-success:translate-y-0"
      />
      <span
        v-if="!isValid && walletAddress"
        class="icon-ico-x rounded-lg bg-slate-900 px-2.5 text-sm top-4 right-2 absolute z-10 transition-all pointer-events-none duration-300 translate-y-2 input-error:opacity-100  input-error:translate-y-0 text-red"
      />
    </div>

    <p
      v-if="hasError"
      class="w-full inline-block text-red text-sm mt-4"
    >
      {{ data?.errorMessage || errorMessage }}
    </p>

    <ButtonButton
      type="submit"
      class="mt-8 w-full"
      :class="{'opacity-40 hover:opacity-40' : isLoading || !isValid}"
      :disabled="isLoading || !isValid"
      @click.prevent.stop="postClaimItem"
    >
      {{ data?.buttonLabel || submitBtnLabel }}
    </ButtonButton>
  </div>
</template>

<script setup>
import Web3 from 'web3';

import { useNft } from '@/composables/useNft';

const emits = defineEmits(['close',]);

const uiStore = useUiStore();
const {
  claimItem,
  toastNotification,
} = storeToRefs(uiStore);

const {
  handlePresentNFTIcon,
} = useNft();

const { $api, $rollbar, $sanity, } = useNuxtApp();

const isLoading = ref(false);
const hasError = ref(false);
const errorMessage = ref();
const data = ref();
const submitBtnLabel = ref('Claim');
const walletAddress = ref(null);
const isValid = ref(false);

await fetchCmsData();

async function postClaimItem() {
  try {
    hasError.value = false;
    isLoading.value = true;
    await $api(`/inventory/${claimItem.value.id}/claim`, {
      method: 'POST',
      body: {
        destination: walletAddress.value,
      },
    });
    emits('close');
    toastNotification.value = {
      type: 'success',
      title: 'You have claimed this item, congratulations!',
      closeAfter: 50000,
    };
  } catch (err) {
    hasError.value = true;
    errorMessage.value = err.response._data.message ? err.response._data.message : 'Something went wrong, please try again later.';
    $rollbar.error('method failed', err);
  } finally {
    isLoading.value = false;
  }
}

async function fetchCmsData() {
  try {
    data.value = await $sanity.fetch(
      `*[_type == "modals" && modalId == "claimItemConfirmation"][0] {
        name,
        modalId,
        heading,
        message,
        buttonLabel,
        showLogo,
      }`
    );
  } catch (err) {
    $rollbar.error('ClaimItemConfirmation fetchCmsData::', err);
  }
}

function validateAddress() {
  isValid.value = !walletAddress.value ? false : Web3.utils.isAddress(walletAddress.value);
}
</script>
